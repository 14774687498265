.home-page {
  .text-zone {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translateY(-50%);
    width: 40%;
    max-height: 90%;

    h1 {
      color: #fff;
      font-size: 56px;
      line-height: 63px;
      margin: 0;
      font-family: 'Coolvetica';
      font-weight: 400;

      &:before {
        content: '<h1>';
        font-family: 'La Belle Aurore', cursive;
        color: #125734;
        font-size: 18px;
        position: absolute;
        margin-top: -40px;
        left: -15px;
        opacity: 0.9;
      }

      &:after {
        content: '<h1/>';
        font-family: 'La Belle Aurore', cursive;
        color: #125734;
        font-size: 18px;
        position: absolute;
        margin-top: 18px;
        margin-left: 20px;
        animation: fadeIn 1s 1.7s backwards;
        opacity: 0.9;
      }
    }

    img {
      margin-left: 20px;
      opacity: 0;
      width: 32px;
      height: auto;
      animation: rotateIn 1s linear both;
      animation-delay: 1.4s;
    }
  }

  h2 {
    color: #8d8d8d;
    margin-top: 20px;
    font-weight: 400;
    font-size: 11px;
    font-family: sans-serif;
    letter-spacing: 3px;
    animation: fadeIn 1s 1.8s backwards;
  }

  .flat-button {
    color: #125734;
    font-size: 13px;
    font-weight: 400;
    letter-spacing: 4px;
    font-family: sans-serif;
    text-decoration: none;
    padding: 10px 18px;
    border: 1px solid #125734;
    margin-top: 25px;
    float: left;
    animation: fadeInAnimation 1s 1.8s backwards;
    white-space: nowrap;
    border-radius: 0.5rem;

    &:hover {
      background: #125734;
      color: rgb(0, 0, 0);
    }
  }
}
