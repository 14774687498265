.tagcloud-wrap {
  // background: gray;
  float: right;
  width: 53%;
  height: 115%;
  margin-top: -3%;

  .tagcloud {
    color: #8f1bb3;
    font-family: 'Poppins', sans-serif;
    font-size: 20px;
    font-weight: 650;
    margin: 10% auto;
  }
  .tagcloud--item:hover {
    color: #125734;
  }
}
